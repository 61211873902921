import React from "react";
import Brand from "../../assets/brand/brand.webp";
import Brand1 from "../../assets/brand/brand1.webp";
import Brand2 from "../../assets/brand/brand2.webp";
import Brand3 from "../../assets/brand/brand3.webp";
import Brand4 from "../../assets/brand/brand4.webp";
import Brand5 from "../../assets/brand/brand5.webp";
import Brand6 from "../../assets/brand/brand6.webp";

import Slider from "react-slick";
import { Link } from "react-router-dom";
import { settings } from "./Slider";

const Brands = () => {
  const brands = [
    { src: Brand, alt: "Brand 1" },
    { src: Brand1, alt: "Brand 2" },
    { src: Brand2, alt: "Brand 3" },
    { src: Brand3, alt: "Brand 4" },
    { src: Brand4, alt: "Brand 5" },
    { src: Brand5, alt: "Brand 6" },
    { src: Brand6, alt: "Brand 7" },
  ];

  return (
    <Slider
      className="global-slick-init attraction-slider nav-style-one nav-color-two"
      {...settings}
    >
      {brands?.map((item) => (
        <div className="guest-two-item">
          <div className="single-guest-three single-guest-two-border radius-5">
            <div className="single-guest-two-flex2">
              <div className="">
                <img src={item.src} alt="img"/>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Brands;
