import bookicon1 from "../assets/img/icons/choice1.png";
import bookicon2 from "../assets/img/icons/choice2.png";
import bookicon3 from "../assets/img/icons/choice3.png";
import bookicon4 from "../assets/img/icons/choice4.png";
import HappyHour from "../assets/img/single-page/happyhour.jpg";
import Staycation from "../assets/img/single-page/staycation.png";
import New1 from "../assets/img/Upcoming/Kota.webp";
import Brand from "../assets/brand/brand.webp";
import Brand1 from "../assets/brand/brand1.webp";
import Brand2 from "../assets/brand/brand2.webp";
import Brand3 from "../assets/brand/brand3.webp";
import Brand4 from "../assets/brand/brand4.webp";
import Brand5 from "../assets/brand/brand5.webp";
import Brand6 from "../assets/brand/brand6.webp";
import guestshade from "../assets/img/guest/guest-shape.png";
import guest1 from "../assets/img/guest/guest1.png";
import guest2 from "../assets/img/guest/guest2.png";
import guest3 from "../assets/img/guest/guest3.png";
import guest4 from "../assets/img/guest/guest4.png";
import guest5 from "../assets/img/guest/guest5.png";
import guest6 from "../assets/img/guest/guest6.png";
import Vadodara from "../assets/img/single-page/vadodra.webp";
import TopBanner from "./Banner";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../lib/axiosConfig";
const SearchEngine = lazy(() => import("./Hotel/SearchEngine"));
const Destination = lazy(() => import("./Home/Destination"));
const Nearby = lazy(() => import("./Home/Nearby"));
const Brands = lazy(() => import("./Home/Brands"));
const LatestNews = lazy(() => import("./Home/News"));

export const Home = () => {
  // -------------------------------- HANDLE HOTEL QUERY -------------------------------------//

  const [properties, setProperties] = useState([]);
  const [selection, setSelection] = useState({
    location: {
      propertyId: "",
      companyId: "",
    },
    checkIn: new Date().toISOString().split("T")[0],
    checkOut: "",
    person: 0,
    children: 0,
    room: 0,
  });

  async function handleOnchangeSelection(key, value) {
    setSelection((prevSelection) => {
      const updatedSelection = { ...prevSelection, [key]: value };
      return updatedSelection;
    });
  }

  // -------------------------------- HANDLE HOTEL QUERY -------------------------------------//

  // -------------------------------- SEARCH HOTEL QUERY API -------------------------------------//

  const companyId = process.env.REACT_APP_COMPANY_ID;
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const res = await axios({
          method: "GET",
          url: `/public/properties/booking-mode/Day?companyId=${companyId}`,
          headers: {
            Authorization: "",
          },
        });

        setProperties(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProperties();
  }, []);
  // -------------------------------- SEARCH HOTEL QUERY API -------------------------------------//

  const brands = [
    { src: Brand, alt: "Brand 1" },
    { src: Brand1, alt: "Brand 2" },
    { src: Brand2, alt: "Brand 3" },
    { src: Brand3, alt: "Brand 4" },
    { src: Brand4, alt: "Brand 5" },
    { src: Brand5, alt: "Brand 6" },
    { src: Brand6, alt: "Brand 7" },
  ];

  return (
    <>
      <TopBanner />
      <div className="location-area">
        <Suspense fallback={<div>Loading...</div>}>
          <SearchEngine
            onChangeSelection={handleOnchangeSelection}
            properties={properties}
            selection={selection}
          />
        </Suspense>
      </div>
      <section className="guest-area pat-100 pab-50">
        <div className="container">
          <div className="section-title-three append-flex">
            <h2 className="title jakarta"> Choose Your Destination </h2>
            <div className="append-attraction append-color-two"></div>
          </div>
          <div className="space-y-2 my-4 mt-5">
            <Suspense fallback={<div>Loading...</div>}>
              <Destination />
            </Suspense>
          </div>
        </div>
      </section>

      <section className="booking-area pat-50 pab-100">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title"> Why We’re Your Best Choice </h2>
            <div className="section-title-shapes"> </div>
          </div>
          <div className="row gy-4 mt-5">
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="single-why center-text bg-white single-why-border radius-5">
                <div className="single-why-icon mb-4">
                  <img src={bookicon1} alt="icon" />
                </div>
                <div className="single-why-contents mt-3">
                  <h4 className="single-why-contents-title">
                    <span>Top-Tier Eco-Friendly Hotels</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="single-why center-text bg-white single-why-border radius-5">
                <div className="single-why-icon mb-4">
                  <img src={bookicon2} alt="icon" />
                </div>
                <div className="single-why-contents mt-3">
                  <h4 className="single-why-contents-title">
                    <span> Book with Confidence </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="single-why center-text bg-white single-why-border radius-5">
                <div className="single-why-icon mb-4">
                  <img src={bookicon3} alt="icon" />
                </div>
                <div className="single-why-contents mt-3">
                  <h4 className="single-why-contents-title">
                    <span>Committed to Carbon Neutrality</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="single-why center-text bg-white single-why-border radius-5">
                <div className="single-why-icon mb-4">
                  <img src={bookicon4} alt="icon" />
                </div>
                <div className="single-why-contents mt-3">
                  <h4 className="single-why-contents-title">
                    <span>Sustainability at Every Step</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Suspense fallback={<div>Loading..</div>}>
        <Nearby />
      </Suspense>

      <section className="question-area pat-50 pab-50">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title">Upcoming Hotels </h2>
            <div className="section-title-shapes"> </div>
          </div>
          <div className="row g-4 mt-5">
            <div className="col-xl-12 col-lg-12">
              <img src={Vadodara} alt="img" className="d-lg-none p-2" />
              <div className="single-guest-one radius-5">
                <div className="single-guest-two-flex gap-3">
                  <div className=" col-xl-6 col-lg-6">
                    <img
                      src={Vadodara}
                      alt="img"
                      className="d-none d-lg-block"
                      height={
                        window.innerWidth >= 998 && window.innerWidth <= 1224
                          ? 340
                          : null
                      }
                    />
                  </div>
                  <div className="content-container col-xl-6 col-lg-6 single-guest-two-contents position-relative">
                    <div className="single-guest-two-contents-country-name">
                      <div className="d-flex align-items-center justify-content-between mt-4 mt-lg-0 ">
                        <h4 className="label fw-bold jakarta">
                          THE ECO Vadodara
                        </h4>
                        <img
                          src={Brand}
                          alt="Vadodata"
                          width={150}
                          className="position-absolute-right-top"
                        />
                      </div>
                      <span className="single-guest-two-contents-country-name mt-4">
                        Eco Hotels and Resorts, which focuses on eco-friendly
                        options and net-zero carbon emissions, on Monday
                        announced the opening of 57-key ’The Eco’ in Vadodara,
                        Gujarat. The new hotel is part of the hospitality
                        company's plans to have a 500-room capacity by the end
                        of December 2024, the firm said in a statement. “We are
                        dedicated to promoting eco-conscious travel options
                        across India, illustrated by our latest property, ’The
                        Eco’ in Vadodara takes us closer to over 500 rooms by
                        the end of December 2024 and a vision to operate 5,000
                        keys in India over the next five years and scale up to
                        15,000 rooms within the next 10 years
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="guest-area pat-50 pab-50">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title"> What our guests think </h2>
            <div className="section-title-shapes"> </div>
          </div>
          <div className="custom-tab guest-wrapper mt-5">
            <div className="guest-wrapper-shapes">
              <img src={guestshade} alt="" />
            </div>
            <div className="custom-tab-menu">
              <ul className="tab-menu guest-wrapper-images list-style-none">
                <li className="guest-wrapper-images-single active">
                  <img src={guest1} alt="" />
                </li>
                <li className="guest-wrapper-images-single">
                  <img src={guest2} alt="" />
                </li>
                <li className="guest-wrapper-images-single">
                  <img src={guest3} alt="" />
                </li>
                <li className="guest-wrapper-images-single">
                  <img src={guest4} alt="" />
                </li>
                <li className="guest-wrapper-images-single">
                  <img src={guest5} alt="" />
                </li>
                <li className="guest-wrapper-images-single">
                  <img src={guest6} alt="" />
                </li>
              </ul>
            </div>
            <div className="row justify-content-center">
              <div className="col-xxl-5 col-lg-6 col-md-8">
                <div className="tab-area">
                  <div className="single-guest tab-item active center-text">
                    <div className="single-guest-thumb">
                      <img src={guest1} alt="" />
                    </div>
                    <div className="mt-4">
                      <p className="single-guest-two-contents-country-name">
                        Comfort and Sustainability Combined Staying at this eco
                        hotel was a fantastic experience. The rooms were
                        incredibly comfortable. It’s clear that this hotel is
                        committed to reducing its carbon footprint, and that
                        made my stay even more enjoyable.
                      </p>
                      <h4 className="single-guest-contents-title mt-3">
                        Amit Patel
                      </h4>
                    </div>
                  </div>
                  <div className="single-guest tab-item center-text">
                    <div className="single-guest-thumb">
                      <img src={guest2} alt="" />
                    </div>
                    <div className="single-guest-contents mt-4">
                      <p className="single-guest-contents-para">
                        "Perfect Balance of Luxury and Eco-Friendliness” This
                        hotel exceeded my expectations. The luxurious amenities
                        didn’t compromise its commitment to sustainability.
                      </p>
                      <h4 className="single-guest-contents-title mt-3">
                        Meera Kapoor
                      </h4>
                    </div>
                  </div>
                  <div className="single-guest tab-item center-text">
                    <div className="single-guest-thumb">
                      <img src={guest3} alt="" />
                    </div>
                    <div className="single-guest-contents mt-4">
                      <p className="single-guest-contents-para">
                        ”A Sustainable Stay Done Right” I was blown away by the
                        level of eco-consciousness at this hotel. They’ve really
                        thought of everything—from water conservation efforts to
                        waste reduction programs.
                      </p>
                      <h4 className="single-guest-contents-title mt-3">
                        Rajesh Kumar
                      </h4>
                    </div>
                  </div>
                  <div className="single-guest tab-item center-text">
                    <div className="single-guest-thumb">
                      <img src={guest4} alt="" />
                    </div>
                    <div className="single-guest-contents mt-4">
                      <p className="single-guest-contents-para">
                        “Sustainability Done Right” This hotel sets the bar high
                        for eco-friendly hospitality. From the moment I arrived,
                        I noticed the attention to detail in their sustainable
                        practices. The energy-efficient appliances were great.
                      </p>
                      <h4 className="single-guest-contents-title mt-3">
                        Sanjay Rao
                      </h4>
                    </div>
                  </div>
                  <div className="single-guest tab-item center-text">
                    <div className="single-guest-thumb">
                      <img src={guest5} alt="" />
                    </div>
                    <div className="single-guest-contents mt-4">
                      <p className="single-guest-contents-para">
                        “Green Luxury at Its Best” I’ve stayed at many hotels,
                        but this one truly stands out for its commitment to the
                        environment. Highly recommend this place if you want
                        luxury with a conscience.”
                      </p>
                      <h4 className="single-guest-contents-title mt-3">
                        Vikram
                      </h4>
                    </div>
                  </div>
                  <div className="single-guest tab-item center-text">
                    <div className="single-guest-thumb">
                      <img src={guest6} alt="" />
                    </div>
                    <div className="single-guest-contents mt-4">
                      <p className="single-guest-contents-para">
                        “Eco-Conscious and Cozy” Staying at this eco hotel was a
                        refreshing change. The atmosphere was cozy and inviting,
                        with an emphasis on sustainability that I really
                        appreciated. It’s the perfect place for anyone looking
                        to travel responsibly.”
                      </p>
                      <h4 className="single-guest-contents-title mt-3">
                        Aishwarya Desai
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="attraction-area section-bg-2 pat-50 pab-50">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title">Offers & Promotions</h2>
            <div className="section-title-shapes"> </div>
          </div>
          <div className="row gy-0 gx-0 mt-5">
            <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="single-attraction-two radius-20">
                <div className="single-attraction-two-thumb">
                  <a href="#" className="gallery-popup-two">
                    <img src={Staycation} alt="img" />
                  </a>
                </div>
                <div className="single-attraction-two-contents">
                  <h4 className="single-attraction-two-contents-title">
                    <Link to="/PromotionOffer">
                      Explore Staycation Packages
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-6">
              <div className="single-attraction-two radius-20">
                <div className="single-attraction-two-thumb">
                  <a href="#" className="gallery-popup-two">
                    <img src={HappyHour} alt="img" />
                  </a>
                </div>
                <div className="single-attraction-two-contents">
                  <h4 className="single-attraction-two-contents-title">
                    <Link to="/PromotionOffer"> </Link> Enjioy the Discounts on
                    our Happy Hour offer
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="question-area pat-50 pab-50">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title">Our Vision </h2>
            <div className="section-title-shapes"> </div>
          </div>
          <div className="row g-4 mt-5">
            <div className="col-xl-12 col-lg-12">
              <div className="single-guest-one single-guest-two-borderbox radius-5">
                <div className="single-guest-two-flex">
                  <div className="col-xl-6 col-lg-6 single-guest-two-contents">
                    <h4 className="single-guest-two-contents-title mt-4">
                      <span className="color-one jakarta">
                        THE WORLD’S FIRST CARBON NEUTRAL
                      </span>
                      <br></br>HOTEL BRAND OF ITS KIND
                    </h4>
                    <div className="single-guest-two-contents-country-name">
                      <span className="single-guest-two-contents-country-name mt-4">
                        Eco Hotels’ objective is to become a leading owner,
                        developer and asset manager of three star premium and
                        economy brands in the BRICS and N11 economies, with
                        India being the first target market, where the
                        opportunity for growth is the greatest.
                      </span>
                      <span className="single-guest-two-contents-country-name mt-4">
                        Eco Hotels has completed the development of the two
                        brands with one operating in Kochi, Kerala, India and
                        intends to increase its operating inventory in excess of
                        15,000 rooms over 10 years by way of acquiring existing
                        properties, refurbishing them to achieve carbon
                        neutrality and also by rolling out new build portfolio
                        using offsite modular building technology.
                      </span>
                      <div className="btn-wrapper mt-4">
                        <Link
                          to="/AboutUs"
                          className="cmn-btn btn-bg-1 radius-10 verdana"
                        >
                          Click here for more details
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    {/* <a href="javascript:void(0)"> */}
                    <img src={New1} alt="img" />
                    {/* </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="history-area pat-50 pab-50 custom-bg-color">
        <div className="container">
          <div className="section-title-three text-left append-flex">
            <h2 className="title"> Our Brands </h2>
            <div className="append-history"></div>
          </div>
          <div className="container py-5 d-none d-sm-block">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 g-4">
              {brands.map((brand, index) => (
                <div key={index} className="col">
                  <div className="bg-white h-100 rounded">
                    <div className="card-body p-4 d-flex align-items-center justify-content-center">
                      <img
                        src={brand.src}
                        alt={brand.alt}
                        className="img-fluid"
                        style={{ maxHeight: "160px", objectFit: "contain" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-12 mt-3 d-sm-none">
            <Suspense fallback={<div>Loading...</div>}>
              <Brands />
            </Suspense>
          </div>
        </div>
      </section>
      <section className="blog-area pat-70 pab-50">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title"> Latest News </h2>
            <div className="section-title-shapes"> </div>
          </div>
          <div className="row gy-4 mt-5">
            <Suspense fallback={<div>Loading...</div>}>
              <LatestNews />
            </Suspense>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
