import hoteld1 from "../assets/img/hotel/hotel.png";
import hoteld2 from "../assets/img/hotel/hotel4.webp";
import hoteld3 from "../assets/img/hotel/hotel5.webp";
import hoteld4 from "../assets/img/kota/room1.jpeg";
import hoteld5 from "../assets/img/kota/room2.jpeg";
import hoteld6 from "../assets/img/kota/room3.jpeg";
import living from "../assets/img/hotel/bed-1.png";
import dining from "../assets/img/hotel/dining.jpeg";
import attraction from "../assets/img/hotel/attraction.webp";
import React from "react";
import EcoLogo from "../assets/brand/brand4.webp";
import nearby1 from "../assets/img/kota/nearby1.webp";
import nearby2 from "../assets/img/kota/nearby2.webp";
import nearby3 from "../assets/img/kota/nearby3.webp";
import nearby4 from "../assets/img/kota/nearby4.webp";
import {
  diningContent,
  LivingContent,
  EventBanquetOne,
  EventBanquetTwo,
  restroBar,
} from "./content/EcoHotel";

import HotelPageBuilder from "./ui/HotelPageBuilder";
import { useMediaQuery, useTheme } from "@mui/material";

const livingDetails = [
  {
    tab: "Overview",
    content: (
      <p>
        Experience unparallel comfort at The Eco Satva, Kota, where each of our
        63 luxurious rooms are designed to blend modern amenities with
        eco-friendly practices. Our rooms offers a perfect blend of luxury,
        comfort, eco-consciousness and provide a peaceful retreat with spacious
        layouts, soothing colors and premium amenities, designed with a modern
        aesthetic, these rooms feature elegant furnishings and a cozy ambience,
        making them ideal for both business and leisure travelers.
        <br />
        Wake up to the sound of birds, with large windows that bring in natural
        light and offer stunning views of the surroundings.
      </p>
    ),
  },
  {
    tab: "Standard Double Room",
    content: LivingContent(
      `The Double Standard Rooms at The Eco Satva Kota offer a perfect blend of luxury, comfort, and eco-consciousness. Designed with a modern aesthetic, these rooms feature elegant furnishings and a cozy ambiance, making them ideal for both business and leisure travelers. `,
      4999
    ),
  },
];

const diningDetails = [
  {
    tab: "Overview",
    content: (
      <p>
        The restaurant and bar at The Eco Satva Kota seamlessly blend luxury
        with eco-friendliness.
        <br />
        The Rooftop Restobar offers a chic open-to-sky dining experience with
        eco-conscious design with a relaxed ambiance.
        <br />
        The Main Restaurant provides a refined indoor setting with a focus on
        Indian and international cuisine, prioritizing organic, locally sourced
        ingredients
      </p>
    ),
  },
  {
    tab: "GG's",
    content: restroBar,
  },
  {
    tab: "SAHAR",
    content: diningContent,
  },
];

const eventDetails = [
  {
    tab: "Overview",
    content:
      "The Eco Satva Kota features two elegant banquet halls, designed to accommodate a range of events while maintaining the hotel’s commitment to sustainability and luxury. These versatile spaces are perfect for hosting corporate functions, social gatherings, and grand celebrations, with state-of-the-art amenities that ensure every event is seamless and memorable.",
  },
  {
    tab: "Banquet 1",
    content: <EventBanquetOne />,
  },
  {
    tab: "Banquet 2",
    content: <EventBanquetTwo />,
  },
];

const aboutHotel = (
  <p>
    Nestled in the heart of Kota, near City Mall and next to Resonance, The Eco
    Satva Kota is a premium mid-range hotel that combines modern luxury with a
    deep commitment to sustainability. Boasting 63 elegantly designed rooms,
    each equipped with energy-saving systems and eco-friendly amenities, the
    hotel offers an unparalleled experience for guests who value comfort and
    environmental consciousness.
    <br />
    <br />
    At The Eco Satva Kota, every detail is designed to deliver a luxurious stay
    that also protects the planet, making it the perfect destination for the
    modern, mindful traveller.
  </p>
);

const HotelDetails = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const sections = [
    {
      id: 1,
      label: "Living",
      image: living,
      type: "living",
      data: livingDetails,
      style: isMobile ? "rounded" : "oval",
    },
    {
      id: 2,
      label: "Dining",
      image: dining,
      type: "dining",
      data: diningDetails,
    },
    {
      id: 3,
      label: "Event",
      image: attraction,
      type: "event",
      data: eventDetails,
    },
    { id: 3, label: "Nearby Attractions" },
  ];

  const coverImages = [
    { img: hoteld1 },
    { img: hoteld2 },
    { img: hoteld3 },
    { img: hoteld4 },
    { img: hoteld5 },
    { img: hoteld6 },
  ];

  const attractions = [
    {
      name: "Garadia Temple",
      location: "Rajasthan",
      image: nearby1,
    },
    {
      name: "City Palace",
      location: "Rajasthan",
      image: nearby2,
    },
    {
      name: "Jagmandir Palace",
      location: "Rajasthan",
      image: nearby3,
    },
    {
      name: "Kota Barrage",
      location: "Rajasthan",
      image: nearby4,
    },
  ];

  return (
    <HotelPageBuilder
      hotelName="The Eco Satva Kota"
      logo={EcoLogo}
      address={
        <p>
          <strong>Address1:</strong> Plot No A-46, Main Jhalwar road,{" "}
          <strong>Address2:</strong> Indraprastha Industrial Area
        </p>
      }
      phone="+91 9004935763"
      email="reservations@ecohotels.in"
      amenities={[]}
      aboutHotel={aboutHotel}
      sections={sections}
      coverImages={coverImages}
      factSheet={{}}
      nearby={attractions}
      nearbyLink="/NearbyAttraction"
    />
  );
};

export default HotelDetails;
