import { Link } from "react-router-dom";
import HotelCard from "./Hotel/HotelCard";
import HotelCardSkeleton from "./skeleton/HotelCardSkeleton";
import HotelCardTwo from "./Hotel/HotelCardTwo";
import { useDispatch, useSelector } from "react-redux";
import { setBookingData } from "../reducer/bookingHotelReducer";
import KochiThumbnail from "../assets/img/kochi/Kochi images/11zon_compressed/thumbnail.webp";
import KotaThumbnail from "../assets/img/kota/thumbnail.png";
import NagpurThumbnail from "../assets/img/nagpur/thumbnail.png";
import Brand2 from "../assets/brand/brand4.webp";
import Brand3 from "../assets/brand/brand1.webp";
import Brand4 from "../assets/brand/brand3.webp";
import { Typography } from "@mui/material";

function DestinationRight({ reserveType, data, isLoading, page, url }) {
  const dispatch = useDispatch();

  function storeRoomDetails(index) {
    dispatch(setBookingData(data[index]));
  }

  const { filterHotelId, filterHotelAmenities } = useSelector(
    (state) => state.hotel_location
  );

  console.log(filterHotelId);

  const hotelList = [
    {
      name: "THE ECO Satva Kota",
      caclPrice: "4999",
      address: "Kota",
      url: "/HotelDetails",
      EcoLogo: Brand2,
      thumbnail: KotaThumbnail,
      location: "kota",
      amenities: [
        { icon: "la-parking", name: "Parking" },
        { icon: "la-tv", name: "TV" },
        { icon: "la-wifi", name: "Wifi" },
        { icon: "la-coffee", name: "Restaurant" },
        { icon: "la-quidditch", name: "Home Service" },
        { icon: "la-wind", name: "AC" },
        { icon: "la-receipt", name: "Reception" },
      ],
    },
    {
      name: (<>EcoValue Kochi <br/> <Typography color="#667085">(Near High Court)</Typography></>),
      caclPrice: "4999",
      address: "Kochi",
      url: "/KochiHotelDetails",
      EcoLogo: Brand3,
      thumbnail: KochiThumbnail,
      location: "kochi",
      amenities: [
        { icon: "la-parking", name: "Parking" },
        { icon: "la-tv", name: "TV" },
        { icon: "la-wifi", name: "Wifi" },
        { icon: "la-wind", name: "AC" },
      ],
    },
    {
      name: "EcoXpress Satva Nagpur",
      caclPrice: "4999",
      address: "Nagpur",
      url: "/NagpurHotelDetails",
      EcoLogo: Brand4,
      thumbnail: NagpurThumbnail,
      location: "nagpur",
      amenities: [
        { icon: "la-parking", name: "Parking" },
        { icon: "la-tv", name: "TV" },
        { icon: "la-wifi", name: "Wifi" },
        { icon: "la-quidditch", name: "Home Service" },
        { icon: "la-wind", name: "Air Conditioning" },
        { icon: "la-receipt", name: "Reception" },
      ],
    },
  ];

  const filterHotelList = hotelList.filter((item) => {
    // Check if filterHotelId is applied
    const matchesHotelId =
      filterHotelId.length === 0 || filterHotelId.includes(item.location);

    // Check if filterHotelAmenities is applied
    const matchesAmenities =
      filterHotelAmenities.length === 0 ||
      item.amenities.some((amenity) =>
        filterHotelAmenities.includes(amenity.icon)
      );

    // Both conditions need to be true for the item to be included in the filtered list
    return matchesHotelId && matchesAmenities;
  });

  return (
    <>
      <div className="shop-grid-contents">
        <div className="grid-list-contents">
          <div className="grid-list-contents-flex">
            <p className="label">Showing 3 - 1 of 3 results</p>
            <div className="grid-list-view">
              <ul className="grid-list-view-flex d-flex tabs list-style-none">
                <li className="grid-list-view-icons active" data-tab="tab-grid">
                  <a href="javascript:void(0)" className="icon">
                    {" "}
                    <i className="las la-border-all"></i>{" "}
                  </a>
                </li>
                {/* <li className="grid-list-view-icons" data-tab="tab-list">
                  <a href="javascript:void(0)" className="icon">
                    {" "}
                    <i className="las la-bars"></i>{" "}
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div id="tab-grid" className="tab-content-item active mt-4">
          <div className="row gy-4">
            {page === "hotel-landing" ? (
              <>
                {filterHotelList.map((item) => (
                  <div key={item.id} className="col-md-6">
                    <HotelCard
                      name={item.name}
                      caclPrice={item.caclPrice}
                      address={item.address}
                      url={item.url}
                      EcoLogo={item.EcoLogo}
                      thumbnail={item.thumbnail}
                      amenities={item.amenities}
                    />
                  </div>
                ))}

                {/* <div className="col-md-6">
                  <HotelCard
                    name={"THE ECO Satva Kota"}
                    caclPrice={"4999"}
                    address={"Kota"}
                    url={url}
                    EcoLogo={Brand2}
                    thumbnail={KotaThumbnail}
                  />
                </div>
                <div className="col-md-6">
                  <HotelCard
                    name={"EcoValue Kochi"}
                    caclPrice={"4999"}
                    address={"Kochi"}
                    url={"/KochiHotelDetails"}
                    thumbnail={KochiThumbnail}
                    EcoLogo={Brand3}
                  />
                </div>
                <div className="col-md-6">
                  <HotelCard
                    name={"EcoXpress Satva Nagpur"}
                    caclPrice={"4999"}
                    address={"Nagpur"}
                    url={"/NagpurHotelDetails"}
                    thumbnail={NagpurThumbnail}
                    EcoLogo={Brand4}
                  />
                </div> */}
              </>
            ) : isLoading ? (
              <HotelCardSkeleton />
            ) : (
              data?.map((item) => (
                <div key={item?._id} className="col-md-6">
                  <HotelCard
                    type={reserveType}
                    name={item?.name}
                    caclPrice={item?.caclPrice}
                    address={"address"}
                    url={url}
                  />
                </div>
              ))
            )}
          </div>
          <div className="row mt-5">
            <div className="col">
              <div className="pagination-wrapper">
                <ul className="pagination-list list-style-none">
                  <li className="pagination-list-item-prev">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-button"
                    >
                      {" "}
                      Prev{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item active">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      1{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      2{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      3{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      4{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      5{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item-next">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-button"
                    >
                      {" "}
                      Next{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="tab-list" className="tab-content-item mt-4">
          <div className="row gy-4">
            {data?.map((item, index) => (
              <div key={item?._id}>
                <HotelCardTwo
                  index={index}
                  type={reserveType}
                  name={item?.name}
                  caclPrice={item?.caclPrice}
                  address={"address"}
                  storeRoomDetails={storeRoomDetails}
                  url={url}
                />
              </div>
            ))}
          </div>
          <div className="row mt-5">
            <div className="col">
              <div className="pagination-wrapper">
                <ul className="pagination-list list-style-none">
                  <li className="pagination-list-item-prev">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-button"
                    >
                      {" "}
                      Prev{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item active">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      1{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      2{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      3{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      4{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-link"
                    >
                      {" "}
                      5{" "}
                    </a>
                  </li>
                  <li className="pagination-list-item-next">
                    <a
                      href="javascript:void(0)"
                      className="pagination-list-item-button"
                    >
                      {" "}
                      Next{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DestinationRight;
