import Vadodara from "../assets/img/single-page/vadodra.webp";
import Brand1 from "../assets/brand/brand.webp";
import hoteld1 from "../assets/img/hotel/hotel.png";
import hoteld2 from "../assets/img/kota/banner1.jpeg";
import hoteld3 from "../assets/img/kota/banner2.jpeg";
import hoteld4 from "../assets/img/kota/room1.jpeg";
import hoteld5 from "../assets/img/kota/room2.jpeg";
import hoteld6 from "../assets/img/kota/room3.jpeg";
import TopBanner from "./Banner";
import React from "react";
import { Link } from "react-router-dom";

export const newsData = [
  {
    title:
      "Eco Hotels and Resorts Limited appoints Arun Sharma as General Manager of The Eco Satva – Kota",
    link: "https://www.hotelierindia.com/appointments/eco-hotels-and-resorts-limited-appoints-arun-sharma-as-general-manager-of-the-eco-satva-kota",
    date: "03",
    month: "Sept",
    description:
      "Eco Hotels and Resorts has appointed Arun Sharma as the General Manager for The Eco Satva in Kota. Sharma brings extensive experience to the role and will focus on improving guest services and hotel operations.",
    image: hoteld1,
  },
  {
    title:
      "Eco Hotels looks to become profitable by next fiscal end; acquires 3 properties",
    link: "https://economictimes.indiatimes.com/industry/services/hotels-/-restaurants/eco-hotels-looks-to-become-profitable-by-next-fiscal-end-acquires-3-properties/articleshow/113983217.cms?from=mdr",
    date: "06",
    month: "Oct",
    description:
      "Eco Hotels is expanding its portfolio with new hotel openings in Sindhudurg. This expansion aims to meet increasing travel demand and enhance the company’s presence in the hospitality sector.",
    image: hoteld1,
  },
  {
    title:
      "EaseMyTrip forays into hospitality space with 13% stake acquisition in Eco Hotels and Resorts",
    link: "https://inc42.com/buzz/easemytrip-forays-into-hospitality-space-with-13-stake-acquisition-in-eco-hotels-and-resorts/",
    date: "Dec",
    month: "2023",
    description:
      "EaseMyTrip has acquired a 13% stake in Eco Hotels and Resorts, marking its entry into the hospitality industry. This strategic investment is expected to boost EaseMyTrip’s growth and diversification in the travel sector.",
    image: hoteld1,
  },
  {
    title:
      "Eco Hotels and Resorts eyes over 2,000 rooms under its ops by March 2025",
    link: "https://www.business-standard.com/companies/news/eco-hotels-and-resorts-eyes-over-2-000-rooms-under-its-ops-by-march-2025-124022500121_1.html",
    date: "25",
    month: "Feb",
    description:
      "Eco Hotels and Resorts plans to expand its operations significantly, targeting over 2,000 rooms by March 2025. This ambitious plan reflects the company’s commitment to growth and increased market presence.",
    image: hoteld4,
  },
  {
    title: "Eco Hotels & Resorts adds net-zero carbon hotel in Kota",
    link: "https://money.rediff.com/news/market/eco-hotels-resorts-adds-net-zero-carbon-hotel-in-kota/12508420240710",
    date: "10",
    month: "july",
    description:
      "Eco Hotels & Resorts has introduced a new net-zero carbon hotel in Kota, enhancing its portfolio of eco-friendly accommodations. This initiative underscores the company’s dedication to sustainability and environmental responsibility.",
    image: hoteld5,
  },
  {
    title: "Eco Hotels expands with The Eco in Vadodara",
    link: "https://www.moneycontrol.com/news/business/companies/eco-hotels-expands-with-the-eco-in-vadodara-12750419.html",
    date: "17",
    month: "June",
    description:
      "Eco Hotels has launched a new property, The Eco, in Vadodara. This expansion is part of the company’s strategy to broaden its presence and offer high-quality, sustainable lodging options.",
    image: hoteld6,
  },
  {
    title:
      "Eco Hotels And Resorts To Open New Property In Aurangabad As Part Of Expansion Plan",
    link: "https://www.ndtvprofit.com/business/eco-hotels-and-resorts-to-open-new-property-in-aurangabad-as-part-of-expansion-plan",
    date: "26",
    month: "Nov",
    description:
      "Green hospitality hotels chain Eco Hotels and Resorts Ltd. on Tuesday said it will open a property in Aurangabad in Maharashtra as part of its strategy to expand into tier -2 tier and tier-3 cities. The mid-segment hospitality company is expected to sign the deal for the 48-room property next week, the company said in a statement.",
    image: hoteld6,
  },
];

function UpcomingHotel() {
  return (
    <>
      <TopBanner />

      <section className="question-area pat-50 pab-50">
        <div className="container">
          <div className="section-title center-text">
            <h2 className="title">Upcoming Hotels </h2>
            <div className="section-title-shapes"> </div>
          </div>
          <div className="row g-4 mt-5">
            <div className="col-xl-12 col-lg-12">
              <img src={Vadodara} alt="img" className="d-lg-none p-2" />
              <div className="single-guest-one radius-5">
                <div className="single-guest-two-flex gap-3">
                  <div className=" col-xl-6 col-lg-6">
                    <img
                      src={Vadodara}
                      alt="img"
                      className="d-none d-lg-block"
                      height={
                        window.innerWidth >= 998 && window.innerWidth <= 1224
                          ? 340
                          : null
                      }
                    />
                  </div>
                  <div className="content-container col-xl-6 col-lg-6 single-guest-two-contents position-relative">
                    <div className="single-guest-two-contents-country-name">
                      <div className="d-flex align-items-center justify-content-between mt-4 mt-lg-0 ">
                        <h4 className="label fw-bold">THE ECO Vadodara</h4>
                        <img
                          src={Brand1}
                          alt="Vadodata"
                          width={150}
                          className="position-absolute-right-top"
                        />
                      </div>
                      <span className="single-guest-two-contents-country-name mt-4">
                        Eco Hotels and Resorts, which focuses on eco-friendly
                        options and net-zero carbon emissions, on Monday
                        announced the opening of 57-key ’The Eco’ in Vadodara,
                        Gujarat. The new hotel is part of the hospitality
                        company's plans to have a 500-room capacity by the end
                        of December 2024, the firm said in a statement. “We are
                        dedicated to promoting eco-conscious travel options
                        across India, illustrated by our latest property, ’The
                        Eco’ in Vadodara takes us closer to over 500 rooms by
                        the end of December 2024 and a vision to operate 5,000
                        keys in India over the next five years and scale up to
                        15,000 rooms within the next 10 years
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hotel-details-area section-bg pat-0 pab-50">
        <div className="container">
          <div className="row mt-5">
            <div className="col-xl-12 col-lg-12">
              <div className="details-left-wrapper">
                <div className="hotel-view-contents">
                  <div className="hotel-view-contents-middle">
                    <div className="details-contents-header-thumb pb-5 pt-3">
                      <h2
                        className="title color-one"
                        style={{
                          color: "#1D2635",
                        }}
                      >
                        {" "}
                        In The News{" "}
                      </h2>
                    </div>

                    {newsData.map((news, index) => (
                      <div
                        className="hotel-view-contents-flex mt-4"
                        key={index}
                      >
                        <Link to={news.link} target="_blank">
                          <div className="hotel-view-contents-icon">
                            <span class="hotel-view-contents-icon-title mb-2 color-one">
                              {news.date} {news.month}
                            </span>
                            <p className="hotel-view-contents-location-para color-heading mb-1">
                              {news.title}
                            </p>
                            <p className="hotel-view-contents-location-para">
                              {news.description}
                            </p>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UpcomingHotel;
